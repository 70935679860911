define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions-products-show-plans-show", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "discourse/lib/ajax-error", "discourse/lib/url", "discourse-common/utils/decorators"], function (_exports, _controller, _object, _computed, _ajaxError, _url, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RECURRING = "recurring";
  const ONE_TIME = "one_time";
  class AdminPluginsDiscourseSubscriptionsProductsShowPlansShowController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "selectedCurrency", [(0, _computed.alias)("model.plan.currency")]))();
    #selectedCurrency = (() => (dt7948.i(this, "selectedCurrency"), void 0))(); // Also defined in settings.
    static #_2 = (() => dt7948.g(this.prototype, "selectedInterval", [(0, _computed.alias)("model.plan.interval")]))();
    #selectedInterval = (() => (dt7948.i(this, "selectedInterval"), void 0))();
    selectedGroup(groupName) {
      return groupName || "no-group";
    }
    static #_3 = (() => dt7948.n(this.prototype, "selectedGroup", [(0, _decorators.default)("model.plan.metadata.group_name")]))();
    availableGroups(groups) {
      return [{
        id: null,
        name: "no-group"
      }, ...groups];
    }
    static #_4 = (() => dt7948.n(this.prototype, "availableGroups", [(0, _decorators.default)("model.groups")]))();
    currencies() {
      return [{
        id: "AUD",
        name: "AUD"
      }, {
        id: "CAD",
        name: "CAD"
      }, {
        id: "EUR",
        name: "EUR"
      }, {
        id: "GBP",
        name: "GBP"
      }, {
        id: "USD",
        name: "USD"
      }, {
        id: "INR",
        name: "INR"
      }, {
        id: "BRL",
        name: "BRL"
      }, {
        id: "DKK",
        name: "DKK"
      }, {
        id: "SGD",
        name: "SGD"
      }, {
        id: "JPY",
        name: "JPY"
      }, {
        id: "ZAR",
        name: "ZAR"
      }, {
        id: "CHF",
        name: "CHF"
      }, {
        id: "PLN",
        name: "PLN"
      }];
    }
    static #_5 = (() => dt7948.n(this.prototype, "currencies", [_decorators.default]))();
    availableIntervals() {
      return [{
        id: "day",
        name: "day"
      }, {
        id: "week",
        name: "week"
      }, {
        id: "month",
        name: "month"
      }, {
        id: "year",
        name: "year"
      }];
    }
    static #_6 = (() => dt7948.n(this.prototype, "availableIntervals", [_decorators.default]))();
    planFieldDisabled(isNew) {
      return !isNew;
    }
    static #_7 = (() => dt7948.n(this.prototype, "planFieldDisabled", [(0, _decorators.default)("model.plan.isNew")]))();
    productId(id) {
      return id;
    }
    static #_8 = (() => dt7948.n(this.prototype, "productId", [(0, _decorators.default)("model.product.id")]))();
    redirect(product_id) {
      _url.default.redirectTo(`/admin/plugins/discourse-subscriptions/products/${product_id}`);
    }
    changeRecurring() {
      const recurring = this.get("model.plan.isRecurring");
      this.set("model.plan.type", recurring ? ONE_TIME : RECURRING);
      this.set("model.plan.isRecurring", !recurring);
    }
    static #_9 = (() => dt7948.n(this.prototype, "changeRecurring", [_object.action]))();
    createPlan() {
      if (this.model.plan.metadata.group_name === "no-group") {
        this.set("model.plan.metadata.group_name", null);
      }
      this.get("model.plan").save().then(() => this.redirect(this.productId)).catch(_ajaxError.popupAjaxError);
    }
    static #_10 = (() => dt7948.n(this.prototype, "createPlan", [_object.action]))();
    updatePlan() {
      if (this.model.plan.metadata.group_name === "no-group") {
        this.set("model.plan.metadata.group_name", null);
      }
      this.get("model.plan").update().then(() => this.redirect(this.productId)).catch(_ajaxError.popupAjaxError);
    }
    static #_11 = (() => dt7948.n(this.prototype, "updatePlan", [_object.action]))();
  }
  _exports.default = AdminPluginsDiscourseSubscriptionsProductsShowPlansShowController;
});